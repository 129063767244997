import React, { Component } from 'react';
import { navigate } from 'gatsby';
import deviceStore from '../../store/deviceStore';
import customerStore from '../../store/customerStore';
import planStore from '../../store/planStore';
import loaderStore from '../../store/loaderStore';
import * as constants from '../../app.constant';
import translate from "../../i18n/translate";
import DeviceDetails from './deviceDetails.component';
import PaymentInformation from './paymentInformation.component';
import PolicyHolder from './policyHolder.coponent';

import * as EN from '../../i18n/messages/en-US';
import * as DE from '../../i18n/messages/de-DE';
import { LOCALES } from '../../i18n';
import languageStore from '../../store/languageStore';
import orderStore from '../../store/orderStore';



class OrderSummary extends Component {

    state = {isOrderSubmitted: false};

    componentDidMount() {
        this.setState({isOrderSubmitted: false});
        orderStore.updatePaymentErrorCode(null); //clear payment status error code from store
        orderStore.updatePaymentErrorMessage(''); //clear payment status error message from store
    }

    getText = (keyName) => {
        const keyArr = keyName.split('.');
        if (keyArr.length > 1) {
            if (languageStore.selectedLanguage === LOCALES.ENGLISH) {
                return EN[keyArr[0]][keyArr[1]];
            } else {
                return DE[keyArr[0]][keyArr[1]]
            }
        }
    }


    validatePhoneNo = (mobileNo) => {
        if (mobileNo.length == 12 || mobileNo.length > 12) {
            let fullNumberArr = (mobileNo).trim('').split('');
            let countryCode = fullNumberArr.splice(0, 2);
            return {
                contactNo: fullNumberArr.join(''),
                countryCode: countryCode.join('')
            }
        }
        else {
            return {
                countryCode: '43',
                contactNo: mobileNo
            }
        }
    }

    getOrderToken = () => {
        return new Promise(resolve => {
            const request_url = process.env.IDENTITY_SERVICE_TOKEN_URL;
            const method_options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'bolt-country-code': constants.HEADER_REQUEST.boltCountryCode,
                    'bolt-tenant-id':  'DigitalNinja'
                },
                body: JSON.stringify({
                    "client_id": process.env.ORDER_CLIENT_ID,
                    "client_secret": process.env.ORDER_CLEINT_SECRET,
                    "grant_type": process.env.ORDER_GRANT_TYPE
                })
            }
            fetch(request_url, method_options)
                .then(res => res.json())
                .then(response => {
                    planStore.saveAccessToken(response.access_token);
                    resolve(response.access_token);
                })
        })
    }

    doPay = async () => {
        if (this.state.isOrderSubmitted) {
            return;
        }
        loaderStore.showLoader(true);
        this.setState({isOrderSubmitted: true});
        const accessToken = await this.getOrderToken();
        const url = process.env.ORDER_API_URL;
        const params = {
            "store_id": orderStore.storeId,
            "device_unique_id": deviceStore.deviceImei,
            "plan_id": planStore.planId,
            "product_id": planStore.productId,
            "device_rrp": String(deviceStore.deviceRrp),
            "device_activation_date": deviceStore.deviceActivationDate,
            "payment_amount": String(planStore.paymentSystemPrice),
            "payment_currency": planStore.currency,
            "company_name": customerStore.companyName,
            "customer_alternative_mobile_no": customerStore.customerSecondaryPhoneNo,
            "bill_address_line1": customerStore.street1,
            "bill_address_line2": customerStore.street2,
            "bill_address_city": customerStore.city,
            "bill_address_state": "",
            "bill_address_postal_code": customerStore.zipCode,
            "customer_email": customerStore.customerEmail,
            "customer_first_name": customerStore.customerFirstName,
            "customer_last_name": customerStore.customerLastName,
            "customer_mobile_no": this.validatePhoneNo(customerStore.customerPhoneNo).contactNo,
            "customer_mobile_no_country_code": this.validatePhoneNo(customerStore.customerPhoneNo).countryCode,
            "customer_age_consent": customerStore.userAgeConsent,
            "customer_consent": customerStore.userConsent,
            "product_price_currency": planStore.currency,
            "product_tenure": String(planStore.productTenure),
            "product_price": String(planStore.fulfillmentSystemPrice),
            "product_name": (this.getText(planStore.productName)).replace('<br/>', ' '),
            "product_coverage_desc": planStore.coverageDesc,
            "device_model": deviceStore.deviceModel,
            "sku_code": deviceStore.skuCode,
            "device_make": deviceStore.deviceMake,
            "promo_definition": planStore.promoDefinition,
            "partner_code": constants.PARTNER_CODE,
            "partner_order_id": orderStore.dreiOrderId,
            "sales_rep_id": orderStore.salesRepId,
            "kbx_code": deviceStore.kbxCode,
            "payment_merchant_id": planStore.merchantId
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                "x-api-key": process.env.ORDER_X_API_KEY,
                "bolt-country-code": constants.HEADER_REQUEST.boltCountryCode,
                "bolt-tenant-id":  'DigitalNinja',
                "bolt-language-code": constants.HEADER_REQUEST.boltLanguageCode,
                "bolt-channel": constants.HEADER_REQUEST.boltChannel
            },
            body: JSON.stringify(params)
        };
        fetch(url, requestOptions)
            .then(res => {
                return res.json()
            })
            .then(response => {
                if (response._status.message.toLowerCase() === "success" && response._status.code === "80001") {
                    planStore.updateOrderNo(response.order_id);
                    if(deviceStore.deviceImei !== "NA"){
                        this.submitPayment();  //not calling payment for deferred flow
                    } else {
                        navigate(constants.ROUTES.PAYMENT_SUCCESS);
                    }
                } else {
                    navigate(constants.ROUTES.PAYMENT_FAIL);
                }
            }).catch(err => {
                console.log(err);
                navigate(constants.ROUTES.PAYMENT_FAIL);
            }).finally(() => {
                // this.setState({isOrderSubmitted: false});
                loaderStore.showLoader(false);
            })
    }

    submitPayment = () => {
        const url = process.env.PAYMENT_API_URL;
        const params = {
            "partner_code": constants.PARTNER_CODE,
            "order_id": planStore.orderNo,
            "msisdn": customerStore.customerPhoneNo,
            "amount": planStore.paymentSystemPrice,
            "product_name": this.getText(planStore.productName).replace("<br/>", " "),
            "product_code": planStore.productId,
            "period_type": planStore.productTenureType,
            "period_length": planStore.productTenure,
            "charging_count": planStore.productTenure,
            "currency": planStore.currency,
            "payment_method": "telco-billing",
            "merchant_id": planStore.merchantId,
            "deferred_flag":deviceStore.deviceImei === "NA" ? true : false
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                "bolt-country-code": constants.HEADER_REQUEST.boltCountryCode,
                "bolt-tenant-id":  constants.HEADER_REQUEST.boltTenantId,
                "bolt-language-code": constants.HEADER_REQUEST.boltLanguageCode,
            },
            body: JSON.stringify(params)
        };
        loaderStore.showLoader(true);
        fetch(url, requestOptions)
            .then(res => {
                return res.json()
            })
            .then(response => {
                loaderStore.showLoader(false);
                if (response.status === 'success') {
                    navigate(constants.ROUTES.PAYMENT_SUCCESS);
                } else {
                    response.result && orderStore.updatePaymentErrorCode(Number(response.result.error_code));
                    response.result && orderStore.updatePaymentErrorMessage(response.result.error_code+ ' ' +response.result.error_message);
                    navigate(constants.ROUTES.PAYMENT_FAIL);
                }
            }).catch(err => {
                err.result && orderStore.updatePaymentErrorCode(Number(err.result.error_code));
                err.result && orderStore.updatePaymentErrorMessage(err.result.error_code+ ' ' +err.result.error_message);
                loaderStore.showLoader(false);
                navigate(constants.ROUTES.PAYMENT_FAIL);
            })
    }

    render() {
        return (
            <div className="form-wrapper order-summary-section">
                <DeviceDetails />
                <PolicyHolder />
                <PaymentInformation />
                <div className="form-cta-wrapper">
                    <button type="button" disabled={this.state.isOrderSubmitted} className="btn btn-block" onClick={this.doPay}><span>{translate('ordersummary.btn.submit')}</span></button>
                </div>
            </div>
        )
    }
}

export default OrderSummary;